<template>
  <div class="preview-product">
    <!--  Header  -->
    <div class="zq-page-title-wrapper d-flex">
        <h3 class="zq-page-title"> {{ formData.name }} </h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>

    <CTabs class="zq--tabs" :active-tab="activeTab" @update:activeTab="setActiveTab">
      <CTab :title="texts.previewPage.productTab">
        <ProductDetails v-if="activeTab === 0" />
      </CTab>
      <CTab :title="texts.previewPage.eventsTab">
        <EventsDetails v-if="activeTab === 1" />
      </CTab>
      <CTab :title="texts.previewPage.actionTypesTab">
        <ActionTypesDetails v-if="activeTab === 2" />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import ProductDetails from '@/generated/ziqni/views/products/ProductDetails';
import EventsDetails from './details/EventsDetails';
import ActionTypesDetails from './details/ActionTypesDetails';
import {mapGetters} from "vuex";
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import {products} from "@/config/descriptions/products"
import {productsTexts} from "@/config/pageTexts/products.json";

export default {
  components: {
    ProductDetails,
    EventsDetails,
    ActionTypesDetails,
    IconWithTooltip,
  },
  data() {
    return {
      descriptions: {
        ...products.preview
      },
      texts: {
        ...productsTexts
      },
      formData: {
        name: '',
      },
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters('products', [ 'product']),
  },
  methods: {
    setActiveTab(val) {
      this.activeTab = val;
    },
  },
  watch: {
    product: {
      deep: true,
      handler: function (val) {
        this.formData = val;
      },
    },
  },
}
</script>

<style lang="scss">
.preview-product {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
<template>
  <div class="action-types-detail content">
    <CRow class="mt-1">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
              id="actionType-table"
              ref="actionTypeTable"
              class="zq--table"
              :items="actionTypesData"
              :fields="tableFields"
              :column-filter="columnFilter"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
              @update:sorter-value="handleSortBy"
              @update:column-filter-value="handleColumnFilter"
              :sorter-value.sync="sorterValue"
            >
              <template #loading>
                <TableLoader/>
              </template>
              <template #id="{ item }">
                <td
                  :class="{'column-shadow-start fixed-column-start': isTableScrollbar}"
                >
                  <router-link :to="{name: `PreviewActionType`, params: {id: item.id, name: item.name} }">
                    {{ item.id }}
                  </router-link>
                </td>
              </template>
              <template #created="{ item }">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <template #transactionTimestamp="{ item }">
                <td>{{ dateFormate(item.transactionTimestamp) }}</td>
              </template>
              <template #metadata="{ item }">
                <td>{{ item.metadata }}</td>
              </template>
              <template #tags="{ item }">
                <td>
                  <span class="text-ellipsis overflow-hidden text-nowrap d-block">
                    {{ item.tags }}
                  </span>
                </td>
              </template>
              <template #system="{ item }">
                <td>
                  <CSwitch
                    color="success"
                    class="mt-1"
                    shape="pill"
                    :checked="item.constraints.includes('system')"
                    labelOn="Yes"
                    labelOff="No"
                    :disabled="true"
                  />
                </td>
              </template>
              <template #id-filter>
                <FilterActionType
                  field="id"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #created-filter>
                <FilterActionType
                  field="created"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #spaceName-filter>
                <FilterActionType
                  field="spaceName"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #memberRefId-filter>
                <FilterActionType
                  field="memberRefId"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #memberType-filter>
                <FilterActionType
                  field="memberType"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #name-filter>
                <FilterActionType
                  field="name"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #metadata-filter>
                <FilterActionType
                  field="metadata"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #tags-filter>
                <FilterActionType
                  field="tags"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #system-filter>
                <FilterActionType
                  field="system"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #key-filter>
                <FilterActionType
                  field="key"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #description-filter>
                <FilterActionType
                  field="description"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
              <template #unitOfMeasureType-filter>
                <FilterActionType
                  field="unitOfMeasureType"
                  @handleChangeData="handleColumnFilter"
                />
              </template>
<!--              <template #select-header class="text-center">-->
<!--                <div class="position-relative zq--table-select-all" v-theme-select-all>-->
<!--                  <ClCheckbox-->
<!--                    :checkedProp="toggleFlag"-->
<!--                    icon="fa-angle-down"-->
<!--                    @handler="selectAll"-->
<!--                  />-->
<!--                </div>-->
<!--              </template>-->
<!--              <template #select="{ item }">-->
<!--                <td :data-id="item.id" class="text-center select-td fixed-column-start">-->
<!--                  <ClCheckbox-->
<!--                    :valueProp="item.id"-->
<!--                    :checkedProp="selectedActionTypes.indexOf(item.id) > -1"-->
<!--                    @handler="checkActionTypes"-->
<!--                  />-->
<!--                </td>-->
<!--              </template>-->
              <template #actions-header>
                <div v-theme-header-actions></div>
              </template>
              <template #actions-filter>
                <div></div>
              </template>
              <template #actions="{ item }">
                <ClTableActionsDropdown
                  @choice="dropDownChoice"
                  :itemProp="item"
                  :actionsProp="dropDownActions"
                  :isTableScrollbarProp="isTableScrollbar"
                />
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import TableLoader from "@/components/table/Loader";
import FilterActionType from '@/components/table/ColumnFilter';
import {mapActions, mapGetters} from "vuex";
import {dateFormate} from '@/utils/dateFormate'
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
import ClTableActionsDropdown from '@/shared/components/ClTableActionsDropdown';

export default {
  name: "ActionTypesDetails",
  components: {
    TableLoader,
    FilterActionType,
    ClTableActionsDropdown
  },
  data() {
    return {
      actionTypesData: [],
      columnFilter: false,
      isTableScrollbar: false,
      dropDownActions: [
        {
          title: this.$t('buttons.preview'),
          handler: 'showPreview',
        },
      ],
      tableFields: [],
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('actionTypes', ['fields', 'loading', 'actionType']),
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc'
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        }
      }
    },
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('products', ['handleGetProducts_item']),
    ...mapActions('actionTypes', ['handleGetActionTypes', 'handleFields']),
    async initialize() {
      this.tableFields = this.fields.filter(item => item !== 'select');

      await this.handleGetProducts_item([this.$route.params.id], 1, 0)
        .then((data) => {
          routerBreadcrumbs(this.$router.currentRoute, {
            name: data[0].name
          });
          if (data[0].actionTypeAdjustmentFactors) {
            const actionTypesIds = data[0].actionTypeAdjustmentFactors.map((item) => {
              return item.actionTypeId
            })
            this.handleGetActionTypes({idArray: actionTypesIds, limit: actionTypesIds.length, skip: 0})
              .then(actionTypes => {
                this.actionTypesData = actionTypes;
              })
          }
        })
    },
    dateFormate(val) {
      return dateFormate(val)
    },
    dropDownChoice(action, item) {
      if (this[action]) {
        this[action](item)
      }
    },
    showPreview(item) {
      this.$router.push({
        name: 'PreviewActionType',
        params: {
          id: item.id,
          name: item.name
        }
      });
    },
    handleSortBy() {},
    handleColumnFilter() {},
  }
}
</script>

<style scoped>

</style>